<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Paquetes Documentos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Basico</li>
                  <li class="breadcrumb-item active">Documentación</li>
                  <li class="breadcrumb-item active">Paquetes Documentos</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-6" :class="form.id ? 'col-md-6' : 'col-md-12'">
                <div class="card-body">
                  <div class="card card-secondary card-outline col-md-12">
                    <div class="card-header">
                      <h3 class="card-title">
                        <i class="fas fa-scroll"></i>
                        Formulario {{ accion }} Paquetes Documentos
                      </h3>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div
                          class="col-md-4"
                          :class="form.id ? 'col-md-12' : 'col-md-4'"
                        >
                          <div class="form-group">
                            <label for="nombre">Nombre</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="nombre"
                              v-model="form.nombre"
                              :class="
                                $v.form.nombre.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </div>
                        </div>
                        <div
                          class="col-md-3"
                          :class="form.id ? 'col-md-12' : 'col-md-4'"
                        >
                          <div class="form-group">
                            <label for="linea_negocio">Linea De Negocio</label>
                            <select
                              class="form-control form-control-sm"
                              v-model="form.linea_negocio_id"
                              :class="
                                $v.form.linea_negocio_id.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            >
                              <option value="">Seleccione...</option>
                              <option
                                v-for="linea_negocio in listasForms.lineas_negocio"
                                :key="linea_negocio.id"
                                :value="linea_negocio.id"
                              >
                                {{ linea_negocio.nombre }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div
                          class="col-md-4"
                          :class="form.id ? 'col-md-12' : 'col-md-4'"
                        >
                          <div class="form-group">
                            <label for="entidad">Entidad</label>
                            <select
                              class="form-control form-control-sm"
                              v-model="form.entidad"
                              :class="
                                $v.form.entidad.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            >
                              <option value="">Seleccione...</option>
                              <option
                                v-for="entidad in listasForms.entidades"
                                :key="entidad.numeracion"
                                :value="entidad.cod_alterno"
                              >
                                {{ entidad.descripcion }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <div class="row">
                        <button
                          :class="form.id ? 'col-lg-2' : 'col-lg-1'"
                          class="btn bg-primary col-lg-1"
                          v-show="!$v.form.$invalid"
                          @click="save()"
                        >
                          <i class="fas fa-paper-plane"></i><br />Guardar
                        </button>
                        <button
                          :class="form.id ? 'col-lg-2' : 'col-lg-1'"
                          class="btn bg-secondary col-lg-1"
                          @click="back()"
                        >
                          <i class="fas fa-reply"></i><br />Volver
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card-body">
                  <div
                    class="card card-secondary card-outline col-md-12"
                    v-show="form.id"
                  >
                    <div class="card-header">
                      <h3 class="card-title">
                        <i class="fas fa-paste"></i>
                        Agregar Documentos
                      </h3>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-8">
                          <div class="form-group">
                            <label for="documento">Documento</label>
                            <v-select
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              v-model="documento"
                              label="descripcion"
                              :options="listasForms.documentos"
                              @input="selectDocumento()"
                            ></v-select>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label for="relevante">Relevante</label>
                            <input
                              type="checkbox"
                              class="form-control form-control-sm"
                              id="relevante"
                              v-model="formDocumento.relevante"
                            />
                          </div>
                        </div>
                        <div class="col-md-2">
                          <button
                            class="btn btn-dark col-md-12 mt-4"
                            v-show="!$v.formDocumento.$invalid"
                            @click="saveDocumentosHasPaquetes()"
                          >
                            <i class="fas fa-save"></i>
                          </button>
                        </div>
                        <div class="card-body p-0">
                          <table
                            id="sitios"
                            class="table table-bordered table-striped table-hover table-sm"
                          >
                            <thead>
                              <tr>
                                <th>Documento</th>
                                <th>Relevante</th>
                                <th>Acción</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="data in documentosHasPaquetes"
                                :key="data.id"
                              >
                                <td v-text="data.descripcion"></td>
                                <td>
                                  <span
                                    class="badge"
                                    v-bind:class="[
                                      data.pivot.relevante == 1
                                        ? 'badge-success'
                                        : 'badge-dark',
                                    ]"
                                  >
                                    {{
                                      data.pivot.relevante == 1 ? "SI" : "NO"
                                    }}
                                  </span>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    class="btn btn-danger btn-sm"
                                    @click="
                                      destroyDocumento(
                                        data.id,
                                        data.pivot.paquete_documento_id
                                      )
                                    "
                                  >
                                    <i class="fas fa-trash-alt"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="card-body">
                  <div
                    class="card card-secondary card-outline col-md-12"
                    v-show="form.id"
                  >
                    <div class="card-header">
                      <h3 class="card-title">
                        <i
                          class="fa fa-cubes fa-lg"
                          style="color: #000000;"
                        ></i>
                        Agregar Características Paquetes
                      </h3>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-2">
                          <div class="form-group">
                            <label for="">Tipo Caracteristicas</label>
                            <select
                              class="form-control form-control-sm"
                              v-model="formCaract.tipo_caracteristica"
                              :class="
                                $v.formCaract.tipo_caracteristica.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            >
                              <option value="">Seleccione...</option>
                              <option
                                v-for="tipoC in listasForms.tipo_caracteristica"
                                :key="tipoC.numeracion"
                                :value="tipoC.numeracion"
                              >
                                {{ tipoC.descripcion }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label for="">Modelo Type</label>
                            <v-select
                              :class="[
                                $v.formCaract.modelo_type.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid',
                                $store.getters.getDarkMode
                                  ? 'dark-vselect'
                                  : '',
                              ]"
                              v-model="modelo_type"
                              placeholder="Descripcion"
                              label="descripcion"
                              class="form-control form-control-sm p-0"
                              :options="listasForms.modelos_types"
                              @search="buscarModelosType"
                              @input="selectModelTypes()"
                            ></v-select>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label for="">Campo</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="campo"
                              v-model="formCaract.campo"
                              :class="
                                $v.formCaract.campo.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label for="">Valor</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="valor"
                              v-model="formCaract.valor"
                              :class="
                                $v.formCaract.valor.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label for="">Descripcion Valor</label>
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              id="descripcion_valor"
                              v-model="formCaract.descripcion_valor"
                              :class="
                                $v.formCaract.descripcion_valor.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </div>
                        </div>
                        <div class="col-md-1">
                          <button
                            class="btn btn-dark col-md-12 mt-4"
                            v-show="!$v.formCaract.$invalid"
                            @click="saveCaracteristicas()"
                          >
                            <i class="fas fa-save"></i>
                          </button>
                        </div>
                        <div class="card-body p-0">
                          <table
                            class="table table-bordered table-striped table-hover table-sm"
                            id="tablaCaracteristicas"
                          >
                            <thead>
                              <tr>
                                <th>Tipo de Caracteristica</th>
                                <th>Modelo type</th>
                                <th>Campo</th>
                                <th>Valor</th>
                                <th>Descripción de valor</th>
                                <th>Acción</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(caract,
                                index) in caracteristicasPaquetes"
                                :key="caract.id"
                              >
                                <td>
                                  <span
                                    v-if="
                                      formActualizarCaract &&
                                        idActualizarCaract == index
                                    "
                                  >
                                    <select
                                      class="form-control form-control-sm"
                                      v-model="formCaract2.tipo_caracteristica"
                                    >
                                      <option value="">Seleccione...</option>
                                      <option
                                        v-for="tipoC in listasForms.tipo_caracteristica"
                                        :key="tipoC.numeracion"
                                        :value="tipoC.numeracion"
                                      >
                                        {{ tipoC.descripcion }}
                                      </option>
                                    </select></span
                                  >
                                  <span v-else>{{
                                    caract.nCaracteristica
                                  }}</span>
                                </td>
                                <td>
                                  {{ caract.modelo_type }}
                                </td>
                                <td>
                                  <span
                                    v-if="
                                      formActualizarCaract &&
                                        idActualizarCaract == index
                                    "
                                  >
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      id="campo"
                                      v-model="formCaract2.campo"
                                    />
                                  </span>
                                  <span v-else>{{ caract.campo }}</span>
                                </td>
                                <td>
                                  <span
                                    v-if="
                                      formActualizarCaract &&
                                        idActualizarCaract == index
                                    "
                                  >
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      id="valor"
                                      v-model="formCaract2.valor"/></span
                                  ><span v-else>{{ caract.valor }}</span>
                                </td>
                                <td>
                                  <span
                                    v-if="
                                      formActualizarCaract &&
                                        idActualizarCaract == index
                                    "
                                  >
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      id="descripcion_valor"
                                      v-model="
                                        formCaract2.descripcion_valor
                                      "/></span
                                  ><span v-else>{{
                                    caract.descripcion_valor
                                  }}</span>
                                </td>
                                <td>
                                  <span
                                    v-if="
                                      formActualizarCaract &&
                                        idActualizarCaract == index
                                    "
                                  >
                                    <!-- Botón para mostrar el formulario de actualizar -->
                                    <button
                                      type="button"
                                      class="btn btn-sm bg-success"
                                      v-show="
                                        formCaract2.tipo_caracteristica
                                      "
                                      @click="guardarActualizacionCaract(index)"
                                    >
                                      <i class="fas fa-save"></i></button
                                  ></span>
                                  <span v-else>
                                    <button
                                      type="button"
                                      class="btn btn-sm bg-navy"
                                      @click="verFormActualizarCaract(index)"
                                    >
                                      <i class="fas fa-edit"></i>
                                    </button>
                                    <button
                                      type="button"
                                      class="btn btn-sm btn-danger"
                                      @click="destroyCaracteristica(caract.id)"
                                      v-if="
                                        $store.getters.can(
                                          'admin.paquetes.delete'
                                        )
                                      "
                                    >
                                      <i class="fas fa-trash-alt"></i>
                                    </button>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "PaqueteDocumentoForm",
  components: {
    vSelect,
  },
  data() {
    return {
      formActualizarCaract: false,
      idActualizarCaract: null,
      documento: null,
      documentoHasPaquete: {},
      caracteristicasPaquetes: {},
      formCaract: {
        tipo_caracteristica: null,
        campo: null,
        valor: null,
        descripcion_valor: null,
        modelo_type: null,
      },
      formCaract2: {
        tipo_caracteristica: null,
        campo: null,
        valor: null,
        descripcion_valor: null,
        modelo_type: null,
      },
      formDocumento: {
        documento_id: null,
        relevante: false,
      },
      form: {},
      accion: "",
      metodo: "",
      seleccion: true,
      modelo_type: [],
      modelo_type2: [],
      listasForms: {
        entidades: [],
        lineas_negocio: [],
        documentos: [],
        tipo_caracteristica: [],
        modelos_types: [],
      },
      documentosHasPaquetes: {},
    };
  },
  validations: {
    form: {
      nombre: {
        required,
      },
      entidad: {
        required,
      },
      linea_negocio_id: {
        required,
      },
    },
    formDocumento: {
      documento_id: {
        required,
      },
    },
    formCaract: {
      tipo_caracteristica: {
        required,
      },
      campo: {
        required,
      },
      valor: {
        required,
      },
      descripcion_valor: {
        required,
      },
      modelo_type: {
        required,
      },
    },
  },
  methods: {
    getEntidades() {
      axios.get("/api/lista/5").then((response) => {
        this.listasForms.entidades = response.data;
      });
    },

    getCaracteristicas() {
      axios.get("/api/lista/174").then((response) => {
        this.listasForms.tipo_caracteristica = response.data;
      });
    },

    getLineasNegocio() {
      axios.get("/api/admin/lineasNegocios/lista").then((response) => {
        this.listasForms.lineas_negocio = response.data;
      });
    },

    buscarModelosType(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/lista/35?descripcion=" + search;
        axios
          .get(url, {
            params: {},
          })
          .then(function(response) {
            me.listasForms.modelos_types = response.data;
            loading(false);
          })
          .catch(function(e) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectModelTypes() {
      this.formCaract.modelo_type = "";
      this.formCaract2.modelo_type = "";
      if (this.modelo_type) {
        this.formCaract.modelo_type = this.modelo_type.cod_alterno;
      }

      if (this.modelo_type2) {
        this.formCaract2.modelo_type = this.modelo_type2.cod_alterno;
      }
    },

    selectDocumento() {
      if (this.documento) {
        this.formDocumento.documento_id = this.documento.id;
        this.documentosHasPaquetes.forEach((element) => {
          if (element.descripcion === this.documento.descripcion) {
            this.documento = {};
            this.formDocumento.documento_id = null;
            this.$swal({
              icon: "error",
              title:
                "El documento seleccionado ya se encuentra asignado dentro del Paquete...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          }
        });
      } else {
        this.formDocumento.documento_id = null;
      }
    },

    getDocumento() {
      axios.get("/api/admin/documentos/lista").then((response) => {
        this.listasForms.documentos = response.data;
      });
    },

    getDocumentosHasPaquetes() {
      axios.get("/api/admin/paquetes/" + this.form.id).then((response) => {
        this.documentosHasPaquetes = response.data[0].documentos;
      });
    },

    saveDocumentosHasPaquetes() {
      this.formDocumento.paquete_documento_id = this.form.id;
      this.formDocumento.accion = true;
      if (!this.$v.formDocumento.$invalid) {
        axios({
          method: "PUT",
          url: "/api/admin/paquetes/assingOrRevokeDocumento",
          data: this.formDocumento,
        })
          .then(() => {
            this.formDocumento = {};
            this.documento = {};
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.getDocumentosHasPaquetes();
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    destroyDocumento(idDocumento, idPaquete) {
      this.$swal({
        title: "Esta seguro de eliminar este documento?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios({
            method: "PUT",
            url: "/api/admin/paquetes/assingOrRevokeDocumento",
            data: {
              paquete_documento_id: idPaquete,
              documento_id: idDocumento,
              accion: false,
            },
          }).then(() => {
            this.getDocumentosHasPaquetes();
            this.$swal({
              icon: "success",
              title: "Se eliminó el documento exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    save() {
      if (!this.$v.form.$invalid) {
        axios({
          method: this.metodo,
          url: "/api/admin/paquetes",
          data: this.form,
        })
          .then((response) => {
            this.form = response.data;
            this.getDocumentosHasPaquetes();
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    saveCaracteristicas(ca = 0) {
      this.formCaract.paquete_documento_id = this.form.id;
      let datos = {};
      if (ca == 0) {
        datos = {
          id: null,
          paquete_documento_id: this.formCaract.paquete_documento_id,
          tipo_caracteristica: this.formCaract.tipo_caracteristica,
          campo: this.formCaract.campo,
          valor: this.formCaract.valor,
          descripcion_valor: this.formCaract.descripcion_valor,
          modelo_type: this.formCaract.modelo_type,
        };
      } else {
        datos = {
          id: this.formCaract2.id,
          paquete_documento_id: this.formCaract.paquete_documento_id,
          tipo_caracteristica: this.formCaract2.tipo_caracteristica,
          campo: this.formCaract2.campo,
          valor: this.formCaract2.valor,
          descripcion_valor: this.formCaract2.descripcion_valor,
        };
      }
      /* if (!this.$v.formCaract.$invalid) { */
      axios({
        method: "PUT",
        url: "/api/admin/paquetes/caracteristicas",
        data: datos,
      })
        .then(() => {
          this.limpiarFormCaract();
          this.$swal({
            icon: "success",
            title: "Se guardo exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.getCaracteristicasPaquete();
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      /* } */
    },

    getCaracteristicasPaquete() {
      axios
        .get("/api/admin/paquetes/Caract/" + this.form.id)
        .then((response) => {
          this.caracteristicasPaquetes = response.data;
        });
    },

    destroyCaracteristica(id) {
      this.$swal({
        title: "Esta seguro de eliminar esta caracteristica?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios.delete("/api/admin/paquetes/Caract/" + id).then(() => {
            this.init();
            this.$swal({
              icon: "success",
              title: "Se elimino la caracteristica exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2500,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    back() {
      return this.$router.replace("/admin/Paquetes");
    },

    init() {
      this.accion = this.$route.params.accion;
      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        this.metodo = "PUT";
        this.documento = this.formDocumento.documento;
        this.getDocumentosHasPaquetes();
        this.getCaracteristicasPaquete();
      } else {
        this.metodo = "POST";
      }
    },

    limpiarFormCaract() {
      this.formCaract = {
        campo: null,
        descripcion_valor: null,
        modelo_type: null,
        tipo_caracteristica: null,
        valor: null,
      };
      this.modelo_type = [];
    },

    verFormActualizarCaract(indice) {
      // Antes de mostrar el formulario de actualizar, rellenamos sus campos
      this.idActualizarCaract = indice;
      this.formCaract2.id = this.caracteristicasPaquetes[indice].id;
      this.formCaract2.tipo_caracteristica = this.caracteristicasPaquetes[
        indice
      ].tipo_caracteristica;
      this.formCaract2.campo = this.caracteristicasPaquetes[indice].campo;
      this.formCaract2.valor = this.caracteristicasPaquetes[indice].valor;
      this.formCaract2.descripcion_valor = this.caracteristicasPaquetes[
        indice
      ].descripcion_valor;

      // Mostramos el formulario
      this.formActualizarCaract = true;
    },

    guardarActualizacionCaract(index) {
      // Ocultamos nuestro formulario de actualizar
      this.formActualizarCaract = false;

      // Actualizamos los datos
      this.caracteristicasPaquetes[index].id = this.formCaract2.id;
      this.caracteristicasPaquetes[
        index
      ].tipo_caracteristica = this.formCaract2.tipo_caracteristica;
      this.caracteristicasPaquetes[index].campo = this.formCaract2.campo;
      this.caracteristicasPaquetes[index].valor = this.formCaract2.valor;
      this.caracteristicasPaquetes[
        index
      ].descripcion_valor = this.formCaract2.descripcion_valor;

      this.saveCaracteristicas(1);
    },
  },
  mounted() {
    this.init();
    this.getLineasNegocio();
    this.getEntidades();
    this.getDocumento();
    this.getCaracteristicas();
  },
};
</script>
